import React from 'react'
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import ImageMeta from "../ImageMeta"
import TheaterVideo from '@bit/azheng.joshua-tree.theater';
import FixedFacewall from "../carousel/FixedFacewall"
import { shuffleArray } from "../../utils/utils"
import  CenterColumn from '../Bulma/CenterColumn'
import Button from '@bit/azheng.joshua-tree.button';

const MiniResidency = ({ post, allReviews }) => {

    shuffleArray(allReviews)

    const facewallReviews = allReviews.splice(0, 4)

    return (
        <div>
            <div className="columns residency-top">
                <div className="column is-2"></div>
                <div className="column has-text-centered">
                    <h1>{post.heading}</h1>
                    <TheaterVideo
                        videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                        overVideo={true}
                        language={"en"}
                        buttonClass="contained"
                    >
                        <ImageMeta
                            cloudName="nuvolum"
                            publicId={post.imageId}

                            width="auto"
                            className="theater-image"
                        >

                        </ImageMeta>
                    </TheaterVideo>
                </div>
                <div className="column is-2"></div>
            </div>

            <div className="columns residency-body">
                <div className="column is-2"></div>
                <div className="column">
                    <MarkdownViewer markdown={post.body} />
                </div>
                <div className="column is-2"></div>
            </div>

            {/* <div className="residency-facewall">
                <FixedFacewall
                    heading={post.facewall.heading}
                    sideColumnIs={1}
                    middleColumnsis={1}
                    reviews={facewallReviews}
                    isGeneric={false}
                    button
                    hideProcedureNames={false}
                />
            </div> */}

            {post.videoSection.hasThisSection && 
                
                <div className="columns video-referrals">
                    <div className="column is-3"></div>
                    <div className="column">
                        <h3 className="has-text-centered">{post.videoSection.heading}</h3>
                    <div className="mini-residency-reviews">
                    {post.videoSection.videos.map((item, i) => (
                        <div key={i} className={`dr-referral`}>
                            <TheaterVideo
                            videoUrl={`https://www.youtube.com/watch?v=${item.youtube}`}
                            hasWatchVideo={false}
                            controls={true}
                            playing={true}
                            language={"en"}
                            >
                            <ImageMeta
                                cloudName="nuvolum"
                                className="hoverable"
                                responsiveUseBreakpoints
                                responsive
                                publicId={
                                item.imageId
                                }
                                version="1580777124"
                                width="auto"
                            ></ImageMeta>
                            </TheaterVideo>
                            <div className="review-block-caption">
                            <h5 className="image-caption">
                                <TheaterVideo
                                videoUrl={`https://www.youtube.com/watch?v=${item.youtube}`}
                                hasWatchVideo={false}
                                controls={true}
                                playing={true}
                                language={"en"}
                                >
                                <a>
                                    {
                                    item.caption
                                    }
                                </a>
                                </TheaterVideo>
                            </h5>
                            <TheaterVideo
                                videoUrl={`https://www.youtube.com/watch?v=${item.youtube}`}
                                buttonText={"Watch Video"}
                                textOnly
                                controls={true}
                                playing={true}
                                onlyButton={true}
                                language={"en"}
                                containerClass="review-block-theater"
                            />
                            </div>
                        </div>
                    ))}
                    </div>
                    </div>
                    <div className="column is-3"></div>
                </div>
            }
                    
            <CenterColumn
                className="home-asird mini-residency-asird"
                leftColWidth={0}
                rightColWidth={0}
                centerColumn={
                    <div className="column asird-grid">
                    <ImageMeta
                        cloudName="nuvolum"
                        publicId="FOMS/DEV/homepage-asird-logo"
                        responsive
                    />
                    <div>
                        <MarkdownViewer markdown={`Beacon Oral & Maxillofacial Surgeons is a proud member of the American Society of Implant & Reconstructive Dentistry (ASIRD), an organization dedicated to educating consumers on the value of a specially trained surgeon and dentist team.`} />
                        <div className="button-row">
                            <Button
                                href={`/education-ASIRD/`}
                                buttonText={`Learn More`}
                            />
                        </div>
                    </div>
                    </div>
                }
            />

        </div>
    )
}

export default MiniResidency
